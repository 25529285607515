export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Захаваць змены"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Захоўваем..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы ўпэўнены?"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скапіяваць ID"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Сёння а ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Учора а ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас няма дазволу размаўляць у гэты канале."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не злучаны"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далучыць паведамленне"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напішыце ваша паведамленне"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перайсці да нізу"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новыя паведамленні"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сціснуць"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запампаваць"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Праглядзець профіль"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рэдагаваць ролі"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выгнаць"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблакіраваць"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скапіяваць Імя карыстальніка:Тэг"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цытатаваць"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрэдагаваць"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пазначыць прачытанымі"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налады сервера"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйсці з сервера"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адключыць гук канала"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уключыць гук канала"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уліковы запіс"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апавяшчэнні"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіраванне эмодзі"])},
      "call-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налады выкліку"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налады запуска"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актыўнасць праграм"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіраванне ботамі"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтэрфейс"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаліць уліковы запіс"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спіс змен"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тэставыя функцыі"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рэдагаванне профілю"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змяніць пароль"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далучыць Google Дыск яшчэ раз"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далучыць Google Дыск"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больш інфармацыі"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гэта інфармацыя бачна ўсім."])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змяніце вашы налады апавяшчэнняў."])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гук апавяшчэння"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робіць файны гук, каб вы заўважылі новая паведамленне"])},
      "notification-mentions-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толькі згадванні/прамыя паведамленні"])},
      "notification-mentions-only-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гук будзе прайгравацца толькі пры вашам згадванні або атрыманні прамога паведамлення."])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push-Паведамленні"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атрымлівайце push-паведамленні, нават калі праграма закрыта."])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бясплатна запампоўвайце вашы любімыя эмодзі! Яны павінны быць не больш, чым 3MB."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фарматы, якія падтрымліваюцца: png, jpg, gif"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дадаць эмодзі"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налады праграмы і запуску"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каб змяняць гэтыя налады, вам трэба спампаваць версію Nertivia для ПК."])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адкрываць Nertivia пры запуску"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абагульняйце праграмы і гульні, якія ў вас адкрыты, дадаўшы іх ніжэй. Гэта заменіць ваш карыстацкі статус, калі праграма адкрыта, і скасуе змену, калі вы закройце праграму."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каб карыстацца функцыяй праграмнай актыўнасці, вы павінны спампаваць версію Nertivia для ПК."])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стварыце карыстальнікаў-батоў, якія дазволяць вам ствараць крутых батоў з дапамогай нашага API."])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дадавайце каманды да батоў, каб карыстальнікі ведалі, што можа рабіць ваш бот"])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стварыць бота"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дадаць каманду"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рэдагаваць бота"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рэдагаваць каманды"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стварыць спасылку запрашэння"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скапіяваць запрашэнне"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіраванне токенам"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паказаць токен"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скапіяваць токен"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скінуць токен"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаліць бота"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы збірайцеся выдаліць ваш уліковы запіс"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Што будзе выдалена"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Што не будзе выдалена"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашы паведамленні ў публічных серверах"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Патрабаванні"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаліць або выйсці са ўсіх сервераў"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаліць ўсіх батоў"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаліць уліковы запіс"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змена мовы можа патрабаваць перазагрузку. Паўдзельнічайце, стварыўшы запыт у рэпазіторыі GitHub."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перакладчыкі"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змяніце выгляд Nertivia."])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колеры"])},
      "time-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фармат часу"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартная тэма"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цёмны AMOLED"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хэлаўін 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прадвызначаныя тэмы"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уласны CSS"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агульныя"])},
      "navigation-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панэль навігацыі"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уключыце назавершаныя або эксперыментальныя функцыі."])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зараз няма даступных тэставых функцый."])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дазволы"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адмін"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уключае ЎСЕ дазволы"])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адпраўленне паведамленняў"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адпраўляць паведамленні ў каналах сервера"])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіраванне ролямі"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абнаўляць і выдаляць ролі"])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіраванне каналамі"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абнаўляць і выдаляць каналы"])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выган"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выганяць карыстальнікаў"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блакіроўка"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выганяць карыстальнікаў і забараняць ім далучацца зноў"])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агульныя"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіраванне каналамі"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіраванне ролямі"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіраванне запрашэннямі"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бачнасць сервера"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіраванне эмодзі"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіраванне апавяшчэннямі"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблакіраваныя карыстальнікі"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіраванне карыстальнікамі"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаліць сервер"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіруйце ролямі вашага сервера."])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартная роля"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роля для батоў"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стварыць новую ролю"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гэта роля ня можа быць выдалена або дадзена іншаму карыстальніку, таму што яна створана для бота. Выдаліце бота, каб выдаліць ролю."])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гэта роль ня можа быць выдалена, таму што гэта стандартная роля, якая выдаецца кожнаму удзельніку."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбраць колер ролі"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва ролі"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налады"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаліць ролю"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаленне ролі..."])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Схаваць роль"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агульныя налады сервера"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіруйце каналамі вашага сервера."])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаліць канал"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаленне канала..."])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва канала"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гэта стандартны канал. Вы можаце наладзіць яго ў агульных наладах сервера. Стандартны канал нельга выдаліць."])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стварыць"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адпаўляць паведамленні"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абмежаванне па адпраўке (секунды)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тэкставы канал"])},
      "category-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Катэгорыю"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал HTML (WIP)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тут вы можаце разблакіраваць карыстальнікаў"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблакіроўка..."])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблакіраваць"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толькі адміны могуць праглядваць запрашэнні."])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стварыць запрашэнні"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карыстальніцкае запрашэнне"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Захаваць карыстальніцкае запрашэнне"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стварыць запрашэнне"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасылка"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стваральнік"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выкарыстанні"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук карыстальніка"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напішыце назву сервера, каб працягнуць"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаленне..."])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змяніць гук апавяшчэння."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гэтыя налады будуць прыменены толькі ў вас і не закрануць іншых."])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уключыць апавяшчэнні"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выключыць гук"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адключыць апавяшчэнні"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " піша..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " і ", _interpolate(_list(1)), " пішуць..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " і ", _interpolate(_list(2)), " пішуць..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " удзельніка(ў) пішуць..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сябры"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нядаўнія"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нататкі"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дадаць сябра"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апавяшчэнні"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Націсніце, каб дадаць уласны статус"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усё прачытана!"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прамыя паведамленні"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серверы"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Даследаванне"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панэль кіравання"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дадайце сяброў, напісаўшы іх імя карыстальніка і тэг."])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дадаванне..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дадаць сервер"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далучыцеся да сервера, увёўшы яго код запрашэння."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стварыце свой сервер. Для пачатку, дайце яму назву."])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далучыцца"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стварыць"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Па-за сеткай"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У сетцы"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактыўны"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не турбаваць"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У пошуках гульні"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Удзельнікі (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Не ў сетцы (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агульныя сябры"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значкі"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далучыліся"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прыпынены"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаліць з сяброў"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прыняць"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасаваць"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дадаць у сябры"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адправіць паведамленне"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агульныя серверы"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гатова!"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аунтэнтыфікацыя..."])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абнавіць"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Даступна абнаўленне"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скапіяваць"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбраць"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаліць"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скапіравана!"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карыстальнік"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выйшаў з сервера"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["быў выгнаны"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["быў заблакіраваны"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["далучыўся да сервера!"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не атрымалася злучыцца з серверам."])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рэжым абмежавання адпраўкі паведамленняў"])}
  }
}